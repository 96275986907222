@import '../../../../../styles/basics';

.bb.bb-appeal-overlay .mobile {
  .bb-appeal-overlay-button {
    border-top: 2px solid $pink-800;
    border-bottom: 2px solid $pink-800;
    border-right: 2px solid $pink-800;
    border-radius: 0 100px 100px 0;
    padding: 8px 10px;

    &.sticky {
      position: absolute;
      z-index: 5950;
    }

    &.open {
      visibility: hidden;
    }

    .small-tag {
      display: inline-block;
      font-weight: 700;
      margin: 0;
      margin-left: 4px;
      padding: 0 8px;
    }

    svg {
      position: relative;
      transform: rotate(-90deg);
      height: 24px;
      width: 24px;
    }
  }
}

.bb-appeal-overlay-popout {
  .close-overlay {
    background-image: url('/static/icons/close-green.svg');
    width: 34px;
    height: 34px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    top: -20px;
    right: 16px;

    &:hover {
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        url('/static/icons/close-green.svg');
      background-size: 30px 30px;
    }
  }
}
