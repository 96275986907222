@import '../../../../../styles/basics';

.current-step-preferences {
  .description {
    margin-bottom: 10px;
  }
  .field-contact_preferences .checkbox-item > label {
    min-width: 110px;
  }

  .small-text {
    font-size: 11px;
    line-height: $line-height-small;
  }
}

.current-step-preferences.donation-form-region-gb {
  .field-contact_preferences {
    margin-bottom: 4px;
  }
}
