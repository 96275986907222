@import '../../../../styles/basics';

.bb-appeal-overlay {
  .bb-appeal-overlay-button {
    position: absolute;
    cursor: pointer;
    background-color: $white;
    transition: left 0.2s;
    left: -4px;

    &:hover {
      left: 0;
    }
  }
}
