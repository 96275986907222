@import '../../../styles/basics';

footer {
  background: $white;
  padding-top: $bb-mobile-standalone-margin;
  padding-bottom: $bb-mobile-standalone-margin;
  border-top: 1px solid $slate-200;

  .big-menu-with-social-links {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 40px;
  }

  .footer-top-region {
    position: relative;
    border-bottom: 1px solid $slate-200;
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(md) {
    padding: $bb-desktop-standalone-margin 0;
    border: none;

    .footer-top-region {
      margin-bottom: 50px;
    }
  }

  @include media-breakpoint-up(lg) {
    .big-menu-with-social-links {
      flex-direction: row;
    }
  }
}
