@import '../../../styles/basics';

// Increase z-index to hide page transition effect between steps.
$form-zindex: 10000000;

// Special class is added to BODY when the form is opened.
body.donation-page-opened {
  // On the large screens we force display of the scroll
  // so that the transition from a long donation landing page
  // to the donation checkout page has no visual jumping effect.
  @include media-breakpoint-up(lg) {
    overflow-y: scroll;
  }

  // <Popup> is added to DOM outside of parent component.
  .popup {
    z-index: $form-zindex + 1;
    overflow-y: hidden;
  }
}

.donation-form-page {
  background: rgb(250, 250, 250);
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: $form-zindex;

  .helper-text {
    font-size: 14px;
  }

  .link-button {
    color: $turquoise-1000;
    cursor: pointer;
    text-decoration: underline;
  }

  // Overwrite styles for some of basic elements.
  h1.progress-stepper-label {
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 24px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }

  h2 {
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 12px;
  }

  h3 {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  a:not([data-component^="button"]) {
    text-decoration: underline;
    &:hover {
      text-decoration-thickness: 3px;
    }
  }

  .primary-button {
    margin-top: 36px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;

    // We can't use disabled HTML property on donation form because it
    // doesn't fire blue event to validate the form.
    // See https://github.com/facebook/react/issues/9142
    &.button-disabled {
      cursor: default;
      background: $slate-200;
      border-color: $slate-200;
      color: $slate-600;
      &:hover {
        cursor: default;
        background: $slate-200;
        border-color: $slate-200;
        color: $slate-600;
      }
    }

    // Treat donation form loading indicator as special case for loading indicator, since it's fatter
    &.submit-donation .spinner {
      left: 20px;
      top: 16px;
    }
  }

  .external-payment-button {
    &.button-visible {
      .paypal-button-wrapper,
      .StripeElement {
        min-height: 55px;
        margin: 36px auto 24px;
      }
    }
  }

  .paypal-preloading {
    display: none;
  }

  .donation-form-footer {
    padding: 32px 0 16px;
    flex-grow: 1;
    background: $white;
    border-top: 2px solid $slate-200;
    @include media-breakpoint-up(lg) {
      padding: 64px 0 46px;
    }

    .payment-logos,
    .payment-copy,
    .google-translate-widget {
      max-width: 600px;
      margin: 0 auto;

      @include media-breakpoint-up(lg) {
        padding: 0 46px;
        max-width: 100%;
        margin: 0;
      }
    }

    .payment-logos {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 0;
      padding-left: 0;
      padding-bottom: 1rem;
      @include media-breakpoint-up(md) {
        display: block;
      }

      img {
        display: block;
        padding-bottom: 18px;
        flex: 50%;
        max-height: 42px;

        @include media-breakpoint-up(md) {
          display: inline-block;
          flex: none;
          padding-bottom: 32px;
          padding-right: 28px;
          padding-inline: 0;
          margin-right: 5%;
          margin-left: 0;
          max-height: 54px;
        }
      }
    }
    .donation-form-footer-line {
      border-top: 2px solid $slate-200;
      margin-inline: -50%;
      margin-bottom: 24px;
      @include media-breakpoint-up(md) {
        border-top: none;
      }
    }
    .donation-form-footer-info {
      border-top: 2px solid $slate-200;
      padding-top: 1rem;
      padding-bottom: 1rem;
      // Remove horizontal scroll for the donation-form-footer-line.
      overflow: hidden;

      p {
        font-size: 16px;
        color: $slate-1000;
        @include media-breakpoint-up(md) {
          max-width: 90%;
        }
      }
      .donation-form-footer-contact [data-component="formatted-text"] p:first-child {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      svg {
        width: 25%;
        margin-right: 25%;
        float: left;
        padding-top: 10px;
      }
      @include media-breakpoint-up(md) {
        border-top: none;
        .container {
          display: flex;
          flex-direction: row-reverse;
          div {
            margin-right: 2%;
          }
        }
        .donation-form-footer-privacy {
          max-width: 50%;
        }
        svg {
          display: none;
        }

      }
    }
    .donation-form-footer-legal {
      border-top: 2px solid $slate-200;
      @include media-breakpoint-up(md) {
        border-top: none;
        padding-top: 1rem;
      }
    }

    .google-translate-widget {
      margin: 0;
      padding-left: 0;
      padding-top: 2rem;
      @include media-breakpoint-up(md) {
        border-top: 2px solid $slate-200;
      }
    }
    .payment-copy {
      margin: 0;
      padding-left: 0;

      @include media-breakpoint-up(lg) {
        width: 75%;
      }

      p {
        font-size: 14px;
        color: $gray-1000;
        margin-top: 2rem;
      }
    }
  }

  .back-button {
    margin-left: -7px;
  }

  .donation-secure-connection {
    text-align: right;
    font-size: 14px;
    line-height: 24px;
    color: $gray-1000;
    margin-top: 24px;

    svg {
      position: relative;
      top: -1px;
      left: -1px;
    }
  }

  .progress-stepper {
    h2 {
      font-weight: normal;
      font-size: 18px;
    }

    .progress-stepper-label {
      text-align: right;

      @include media-breakpoint-up(sm) {
        text-align: center;
      }
    }
  }

  .description {
    color: $slate-1200;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 8px;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
  }

  .form-field-with-description {
    margin-bottom: 36px;
    [data-component="field-template"] {
      margin-bottom: 0;
      &.error {
        margin-bottom: -4px;
      }
    }
  }

  .radio-buttons-inline {
    [data-component="field-type-radios-button"],
    [data-component="field-type-radios-button--selected"] {
      display: inline-block;
      margin-right: 24px;

      &.selected .label {
        text-shadow: 0 0 0.5px $turquoise-1000;
      }
    }
  }

  .payment-logo img {
    height: 40px;
  }

  .donation-form-page-layout {
    position: relative;
    max-width: 532px;
    margin: 0 auto;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      max-width: 990px;
      padding-left: 16px;
      padding-right: 16px;
      justify-content: center;
    }

    .donation-form-form {
      max-width: 500px;
      flex-grow: 1;
      margin: 0 auto;

      @include media-breakpoint-up(md) {
        margin: 0 60px;
      }

      .donation-form-container {
        padding-left: 16px;
        padding-right: 16px;

        @include media-breakpoint-up(md) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .checkout-image {
      min-width: 335px;
      max-width: 335px;
      min-height: 450px;
      margin-bottom: 100px;
    }

    .donation-form-active-step-container {
      position: relative;

      .checkout-image {
        position: absolute;
        top: 0;
        right: -395px;
      }
    }
  }

  &.donation-form-placeholder {
    //.step-indicator-placeholder {
    //  height: 36px;
    //  background-color: $slate-200;
    //}

    // TODO: replace it with .loading-text from LoadingText atom
    .loading-animation {
      color: $gray-1000;
      font-size: 18px;
    }
  }

  .donation-form-impact {
    margin-top: 20px;
    .impact-message {
      width: 100%;
      max-width: none;
    }
  }
}
