///////////////////////
/// BOOTSTRAP GRID ////
///////////////////////

/* Override grid gutter width for grid padding */
$grid-gutter-width: 24px;

@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";

////////////////////////////
/// GRID CUSTOMIZATIONS ////
////////////////////////////

// set base padding for no-margin container to double, but only for smallest displays
.container {
  // Special handling to make mobile have bigger spacing on
  // both sides of the screen.
  @include media-breakpoint-down(md) {
    padding-left: 20px;
    padding-right: 20px;

    .row {
      margin-right: -20px;
      margin-left: -20px;

      [class*='col-'] {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .row {
    // add a vertical gutter to columns so Cards can be uniformly shown while
    // using responsive Grid semantics
    [class*='col-'] {
      margin-bottom: 24px;
    }
  }

  &.compact-grid {
    .row {
      margin-left: -6px;
      margin-right: -6px;

      [class*='col-'] {
        padding-left: 6px;
        padding-right: 6px;
        margin-bottom: 12px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.wide-grid {
      overflow: hidden;

      .row {
        margin-left: -20px;
        margin-right: -20px;

        [class*='col-'] {
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.container-xl, .container-lg, .container-md, .container-sm, .container {
  @include media-breakpoint-up(lg) {
    max-width: 1100px;
    // We use 70px padding to avoid overlapping with Share block on desktop.
    padding-left: 70px;
    padding-right: 70px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1420px;
  }
}
