// Next.js doesn't allow importing non-scoped styles on component level so they
// are all listed below as one global style sheet.
// When the component is migrated to SCSS modules, it can be deleted from here.
//@import '../components/01_atoms/Alert/style';
//@import '../components/01_atoms/ErrorOverlay/style';
//@import '../components/01_atoms/HorizontalLine/style';
//@import '../components/01_atoms/LoadingText/style';
//@import '../components/01_atoms/NoResultsMessage/style';
// @import '../components/02_molecules/StickyNav/style';
@import '../components/03_organisms/DonationForm/steps/06_PaymentsStep/DonationReview/style';
@import '../components/03_organisms/DonationForm/steps/01_AmountPersonalInfoStep/style';
@import '../components/03_organisms/DonationForm/steps/02_AddressStep/style';
@import '../components/03_organisms/DonationForm/steps/03_ContactPreferencesStep/style';
@import '../components/03_organisms/DonationForm/steps/06_PaymentsStep/style';
@import '../components/03_organisms/DonationFormGenerics/style';

// Source code variant behavior default styles.
.behaviour-settings__source-code-variant--source-code {
  display: none;
}
