@import '../../../../styles/basics';

.search-input {
  form {
    [data-component="field-right-icon"] {
      display: none;
    }

    input[name='query'] {
      width: 100%;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid $slate-200;
      line-height: 28px;
      overflow: hidden;
      resize: none;
      @include media-breakpoint-up(md) {
        font-size: 18px;
      }

      &::placeholder {
        color: $slate-400;
      }

      &:focus {
        box-shadow: none;
        outline: none;
        border-bottom: 2px solid $turquoise-1000;
      }

      &::-webkit-clear-button,
      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
        -webkit-appearance: none;
      }

      &::-ms-clear {
        display: none;
      }
    }

    [data-component="field-template"].error.active input[name='query'] {
      border-color: $red-1000;
    }

    [data-component="field-template"] {
      align-self: center;
      margin-bottom: 0;

      [data-component="field-error"] {
        position: absolute;
        bottom: -24px;
        left: 2px;
        @include media-breakpoint-up(md) {
          left: 6px;
        }
      }
    }

    &.goto {
      [data-component="field-template"] {
        flex-grow: 1;

        input {
          color: $turquoise-1000;
          font-size: 18px;
          @include media-breakpoint-up(md) {
            font-size: 28px;
          }
        }
      }

      button {
        width: 32px;
        height: 32px;
        margin: 0;
        margin-bottom: 4px;
        margin-left: 10px;
        padding: 4px;
        @include media-breakpoint-up(md) {
          margin-bottom: 8px;
          width: 40px;
          height: 40px;
        }

        svg {
          vertical-align: text-top;
          margin-top: 1px;
          height: 18px;
          width: 18px;
          @include media-breakpoint-up(md) {
            height: 22px;
            width: 22px;
          }

          g {
            stroke: $white;
          }
        }
      }
    }

    &.search,
    .search {
      position: relative;

      input {
        @include media-breakpoint-up(md) {
          padding-bottom: 6px;
        }
      }

      &:not(.loading) .field::after {
        background-image: url('/static/icons/search.svg');
        background-size: 22px;
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        height: 22px;
        width: 22px;
        right: 4px;
        top: 2px;
        @include media-breakpoint-up(md) {
          height: 28px;
          width: 28px;
        }
      }

      .spinner {
        position: absolute;
        right: 4px;
        top: 4px;
      }
    }
  }

  mark {
    display: block;
    color: $slate-1200;
    margin-top: 8px;
    @include media-breakpoint-up(md) {
      margin-top: 16px;
    }
  }
}
