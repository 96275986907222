//////////////////
/// TYPOGRAPHY ///
//////////////////

/* This font is only used on country pages. */
@font-face {
  font-family: 'Khand Medium';
  src: local('Khand Medium'), local('Khand-Medium'),
    url('/static/fonts/Khand-Medium.woff2') format('woff2'),
    url('/static/fonts/Khand-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-display: swap;
}

/// RESET ///

body {
  font-family: $font-family-stack-sans;
  color: $slate-1200;
  line-height: $line-height-normal;
}

h1,
.header-one,
h2,
.header-two,
h3,
h4,
h5,
h6,
p,
q,
mark {
  margin: 0;
  padding: 0;
  background: none;
}

/// HEADERS ///

h1,
.header-one {
  font-weight: $font-weight-bold;
  font-size: 32px;
  line-height: $line-height-normal;
  @include media-breakpoint-up(md) {
    font-size: 60px;
  }
}

h2,
.header-two {
  line-height: $line-height-normal;
  font-size: 28px;
  @include media-breakpoint-up(md) {
    font-size: 36px;
  }
}

h2,
.header-two,
h3,
.header-three,
h4,
h5,
h6 {
  font-weight: bold;
}

h3,
.header-three {
  font-size: 16px;
  line-height: $line-height-large;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 22px;
  }
}

h4 {
  font-size: 18px;
  line-height: $line-height-large;
}

h5 {
  font-size: 16px;
  line-height: $line-height-large;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

h6 {
  font-size: 14px;
  line-height: $line-height-large;
}

/// BODY TEXT

a,
a > p {
  color: $turquoise-1000;
  text-underline-offset: 2px;

  &:hover,
  &:hover p {
    color: $turquoise-1200;
  }

  &.ignore-visited {
    &:visited:hover {
      color: $turquoise-1200;
    }

    &:visited {
      color: $turquoise-1000;
    }
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

p,
.paragraph {
  font-size: 16px;
  line-height: $line-height-large;
  margin-bottom: 18px;
  color: $slate-1200;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }

  .small-tag {
    line-height: $line-height-normal;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      line-height: $line-height-large;
      font-size: 16px;
    }
  }
}

q {
  &,
  & > p {
    font-size: 18px;
    line-height: $line-height-normal;
    color: $turquoise-1000;
    @include media-breakpoint-up(md) {
      font-size: 28px;
      line-height: $line-height-large;
    }
  }

  & > p {
    margin-bottom: 0;
  }

  &::before,
  &::after {
    content: none;
  }
}

.small-tag > q {
  font-size: 18px;
  line-height: $line-height-large;
  padding-left: 0;

  &::before {
    content: '';
    padding-right: 0;
  }
  @include media-breakpoint-up(md) {
    font-size: 22px;
  }
}

mark {
  font-size: 14px;
  line-height: $line-height-normal;
  color: $gray-1000;

  b,
  strong {
    color: $slate-1200;
  }
}

ul,
ol {
  padding: 0;

  li {
    padding-left: 24px;
  }
}

li {
  position: relative;
  font-size: 16px;
  line-height: $line-height-large;
  margin-bottom: 16px;
  color: $slate-1200;
  @include media-breakpoint-up(md) {
    font-size: 18px;
    margin-bottom: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 10px;
  }
}

ul li {
  display: block;

  &::before {
    width: 8px;
    height: 8px;
    background: $turquoise-1000;
    border-radius: 50%;
  }
}

// TODO -proper styling, see https://www.456bereastreet.com/archive/201105/styling_ordered_list_numbers/
ol li {
  padding-left: 6px;
  margin-left: 18px;
}

ul.fat-list > li,
li.fat-list {
  &::before {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: $slate-1200;
    margin-right: 10px;
    @include media-breakpoint-up(md) {
      top: 12px;
    }
  }
}

ul.link-list > li,
li.link-list {
  display: block;

  &::before {
    border-radius: 0;
    background: url('/static/icons/icons-misc-external-link.svg') no-repeat center center;
    height: 12px;
    width: 12px;
    top: 6px;
    @include media-breakpoint-up(md) {
      top: 8px;
    }
  }
}

ul.icon-list > li,
li.icon-list {
  display: block;
  margin-bottom: 6px;
  padding-left: 16px;

  &::before {
    border-radius: 0;
    background: url('/static/icons/chevron-turquoise-right.svg') no-repeat center center;
    height: 10px;
    width: 8px;
    top: 8px;
    @include media-breakpoint-up(md) {
      top: 10px;
    }
  }

  > a,
  > p > a {
    font-size: 16px;

    &,
    &:visited,
    &:hover,
    &:active {
      color: $slate-1200;
    }
  }

  > p:last-child {
    margin: 0;
  }
}
