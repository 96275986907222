@import '~bootstrap/scss/utilities';

$utilities: map-merge(
    $utilities,
    (
      // Add utilities which we used in Bootstrap v4 but which have gone from Boostrap v5.
      // In Bootstrap v5 they were replaced with padding-start, padding-end, margin-start, margin-end.
      // See https://getbootstrap.com/docs/5.0/utilities/spacing/.
      "padding-left": (
        responsive: true,
        property: padding-left,
        class: pl,
        values: $spacers
      ),
      "padding-right": (
        responsive: true,
        property: padding-right,
        class: pr,
        values: $spacers
      ),
      "margin-left": (
        responsive: true,
        property: margin-left,
        class: ml,
        values: $spacers
      ),
      "margin-right": (
        responsive: true,
        property: margin-right,
        class: mr,
        values: $spacers
      ),
    )
);

// Add tool which generates utilities.
@import '~bootstrap/scss/utilities/api';

.hide-desktop {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hide-mobile {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
