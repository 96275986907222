@import '../../../styles/basics';

.text-input-with-selects {
  .search {
    input[name='query'] {
      border-color: $gray-1000;
      font-size: 20px;

      @include media-breakpoint-up(md) {
        font-size: 28px;
      }
    }

    &:not(.loading) .field::after {
      @include media-breakpoint-up(md) {
        background-size: cover !important;
        margin-top: 6px;
      }
    }

    .spinner {
      right: 0 !important;
      @include media-breakpoint-up(md) {
        right: 4px !important;
        top: 10px !important;
      }

      span {
        width: 20px;
        height: 20px;
        border-width: 2px;
        @include media-breakpoint-up(md) {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .selects {
    margin-top: 24px;
    @include media-breakpoint-up(md) {
      display: flex;
      margin-top: 40px;
    }

    > [data-component="field-template"] {
      margin-bottom: 16px;
      @include media-breakpoint-up(md) {
        width: 100%;
        margin: 0 12px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .clear {
    color: $turquoise-1000;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    @include media-breakpoint-up(md) {
      font-size: 18px;
      margin: auto 0 16px;
    }

    svg {
      height: 10px;
      width: 10px;
      margin-top: -1px;
      margin-left: 3px;

      & > g > path {
        stroke: $white;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        color: $turquoise-1200;

        svg g g path {
          stroke: $turquoise-1000;
        }
      }
    }
  }
}
