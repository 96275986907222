@import '../../../../styles/basics';

.footer-small-menu {
  margin-top: 10px;
  margin-bottom: 36px;
  @include media-breakpoint-up(md) {
    margin-bottom: 26px;
  }

  ul {
    @include list-unstyled();

    li {
      font-size: 14px;
      line-height: $line-height-normal;
      margin-bottom: 20px;
      padding-left: 0;
      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-right: 2.5em;
        margin-bottom: 0;
      }
    }
  }
}
