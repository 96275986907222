@import '../../../styles/basics';

// IMPORTANT: Dont use 'cookie-consent-dialog' class name to avoid issues
// with Ghostery extension.
.cw-cookie-consent-dialog > div {
  img {
    max-width: 76px;
    min-height: 32px;
  }

  .title {
    margin: 10px auto;
    text-align: center;
    font-size: 16px;
  }

  .description {
    text-align: center;

    p {
      font-size: 16px;
    }
  }

  .buttons {
    [data-component^="button-"] {
      font-size: 16px;
      margin: 0;
    }

    .ignore-button {
      display: inline-block;
      margin-right: 15px;
      padding: 10px 20px;
      color: $turquoise-1000;
      cursor: pointer;
      font-size: 16px;

      &:hover {
        color: $turquoise-1200;
        text-decoration: underline;
      }
    }
  }
}

.cw-cookie-consent-dialog.cookie-consent-context-dialog {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparentize($biscuit-100, 0.05);

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;

    @include media-breakpoint-up(md) {
      padding: 30px 50px 20px;
    }
  }
}

.popup.popup-cookie-consent-dialog {
  background-color: transparentize($biscuit-100, 0.05);

  .cookie-consent-context-dialog {
    position: relative;
    background: none;

    > div {
      justify-content: normal;
    }
  }

  .popup-navigation .close-icon {
    circle,
    path {
      stroke: $black;
    }
  }
}
