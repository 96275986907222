@import '../../../styles/basics';

.toggle-group-cards {
  position: relative;

  .toggle-element {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  .description {
    margin-top: 20px;
    margin-bottom: 24px;
    border-radius: 10px;
    background-color: $slate-200;
    padding: 16px 24px;
    font-size: 14px;
    line-height: $line-height-large;

    div {
      margin-bottom: 5px;
    }
  }
}

.toggle-group-icons {
  display: inline-block;

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 5px;

    @include media-breakpoint-up(md) {
      gap: 10px 12px;
    }
  }
}
