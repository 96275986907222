.bb {
  width: 100%;
  scroll-margin-top: 80px;

  > .container > h1,
  > .container > h2,
  > .container > h3,
  > .container > h4,
  > .container > h5 {
    @include bb-heading-spacing();
  }

  @include media-breakpoint-up(lg) {
    scroll-margin-top: 60px;
  }
}

// Very first heading after the hero block should be centered.
// All other h2 headings should be left aligned. We hardcode this behaviour
// to reduce cognitive load on content editors.
.bb-hero + .bb.h2 {
  text-align: center;
}

// For body blocks following Heading body block we using bottom spacing from
// Heading body block instead of top spacing of body block itself.
.bb.h2 + .bb {
  margin-top: 0;
}
