@import '../../../../styles/basics';

.search-result {
  // taking into account link chevron padding
  margin-top: 16px;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }

  a,
  mark {
    display: block;
  }

  mark:nth-child(2) {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
