@import '../../../../../../styles/basics';

.donation-review-details {
  margin-top: 27px;
  > div {
    text-align: center;

    span {
      display: block;
      @include media-breakpoint-up(sm) {
        display: inline-block;
      }
    }
  }

  .donation-review-amount {
    color: $slate-1200;
    font-weight: $font-weight-bold;
  }
}
