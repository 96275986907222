@import '../../../../styles/basics';

.top-banner {
  background-color: $slate-1200;
  line-height: 16px;
  font-size: 12px;
  color: $white;
  position: relative;
  text-align: left;

  a {
    color: $white;
    display: block;
    padding: 10px 36px 10px 10px;

    &.top-banner-cta-emergency {
      padding-left: 36px;
    }

    &:hover {
      color: $white;
      text-decoration: none;

      .chevron {
        transform: translateX(4px);
        path {
          fill: transparent;
        }
      }

      .emergency-icon {
        path {
          fill: $white;
        }
      }
    }

    &:active {
      transition: opacity ease-out 0.15s;
      opacity: 0.9;
    }
  }

  .label {
    font-weight: $font-weight-bold;
  }

  .chevron {
    position: absolute;
    width: 6px;
    right: 16px;
    top: calc(50% - 8px);
    transition: transform ease-out 0.15s;

    svg {
      width: 6px;
    }
  }

  .emergency-icon {
    position: absolute;
    left: 6px;
    top: calc(50% - 11px);

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .emergency-tag-label {
    display: none;
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
    text-align: center;

    a {
      padding-top: 17px;
      padding-bottom: 17px;

      &.top-banner-cta-emergency {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .chevron {
      position: absolute;
      right: inherit;
      margin-left: 18px;
    }

    .top-banner-cta-emergency .chevron {
      top: calc(50% - 10px);
    }

    .emergency-icon {
      display: none;
    }

    .emergency-tag-label {
      display: inline-block;
      margin-right: 16px;
    }
  }
}
