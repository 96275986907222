@import '../../../../../styles/basics';

.bb.bb-appeal-overlay .desktop {
  .bb-appeal-overlay-button {
    height: 64px;
    border-top: 2px solid $pink-800;
    border-left: 2px solid $pink-800;
    border-right: 2px solid $pink-800;
    border-radius: 20px 20px 0 0;
    padding: 16px 24px;
    transition: opacity 0.3s, left 0.2s;
    opacity: 1;
    // orient it into the correct left screen placement
    transform-origin: top left;
    transform: rotate(90deg) translateY(-100%);

    &.sticky {
      position: fixed;
      top: 50%;
      transform: rotate(90deg) translateX(-50%) translateY(-100%);
      z-index: 6750;
    }

    &.open {
      opacity: 0;
      left: 335px;
    }

    p {
      white-space: nowrap;
      margin: 0;
      margin-left: 16px;
    }

    svg {
      transform: rotate(-90deg);
      height: 30px;
      width: 30px;
    }
  }

  .bb-appeal-overlay-popout {
    .close-overlay {
      width: 28px;
      height: 28px;
      background-image: url('/static/icons/carousel-button.svg');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      top: 16px;
      right: -14px;
      transform: rotate(180deg);
      transition: right 0.2s;

      &:hover {
        right: -12px;
      }
    }
  }
}
