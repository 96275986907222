@import '../../../../../styles/_basics';

.donation-form-page {
  .form-field-with-description.shortform {
    @include media-breakpoint-up(md) {
      margin-top: 35px;
    }
  }

  .amount-short-step-details {
    color: $slate-1200;

    h2 {
      margin-top: 40px;
    }

    div {
      font-size: 18px;
    }
  }

  a.this-is-not-me {
    display: inline-block;
    margin-top: 16px;
    font-size: 14px;
    cursor: pointer;
    color: $turquoise-1000;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
