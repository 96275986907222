@import '../../../../styles/basics';
@import '../AppealBanner/_style';

.top-banner.top-banner-event {
  text-align: center;
  padding: 10px;
  background-color: $turquoise-1000;
  [data-component^="button"] {
    display: inline-block;
    margin-bottom: 0;
    &[data-component^="button-"] {
      border: 1px solid $white;
    }
  }
  .text {
    display: none;
  }

  @include media-breakpoint-up(md) {
    .text {
      display: inline-block;
      font-weight: $font-weight-bold;
      font-size: 18px;
      margin-right: 16px;
      position: relative;
      top: 2px;
    }
  }
}
