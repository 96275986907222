@import '../../../styles/basics';

.button-field {
  position: relative;
  background-color: $pink-800;
  transition: background-color 0.2s;
  border-radius: 10px;
  padding: 16px 20px;
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 250px;
    padding: 30px;
  }

  // spacing between stacked elements on mobile
  &:not(:last-child) {
    margin-bottom: 12px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  > *,
  > .header-one,
  > .header-two {
    color: $white;
  }

  > .header-one {
    @include media-breakpoint-down(lg) {
      font-size: 44px;
    }
  }

  .amount-info {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .description-title {
    font-size: 14px;
  }

  form {
    margin: 0;
    flex-grow: 1;

    // positions the validation label so that the rest of the element's flow isn't disrupted on desktop
    [data-component="field-error"] {
      position: absolute;
      left: 0;
      top: 45px;
    }
  }

  [data-component^="button-"] {
    margin: 0;
  }

  &:not(.button-field-form) {
    [data-component^="button-"] {
      pointer-events: none;
    }

    p {
      line-height: $line-height-large;
      width: 100%; // for IE11
      margin-bottom: 0;
      font-size: 16px;
      @include media-breakpoint-up(md) {
        margin-top: 8px;
        margin-bottom: 24px;
      }
    }

    .chevron,
    .spinner.d-md-none {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    .chevron {
      background: none;
      height: 20px;
      right: 24px;
      transition: right 0.2s;

      // avoid visual bug when manipulating position
      path {
        fill: none;
      }
    }

    &.disabled:not(.loading) {
      opacity: 0.8;
    }

    &:not(.disabled) {
      cursor: pointer;

      &:hover {
        background-color: $pink-1000;

        .chevron {
          right: 20px;
        }
      }
    }
  }
}

.button-field-new {
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &.type-plain {
    background-color: $biscuit-100;
  }

  &.desktop {
    border-radius: 16px;
    justify-content: flex-end;

    &.type-plain {
      padding: 32px 24px;
      width: 280px;
      min-height: 240px;
      justify-content: space-between;
      text-align: center;

      @include media-breakpoint-up(xl) {
        width: 28%;
        align-items: center;
      }

      .title {
        transition: transform 0.2s;
      }

      &:hover,
      &:focus {
        &:not(.disabled) {
          background-color: $slate-100;

          .title {
            transform: scale(1.1);
          }
        }
      }

      .title {
        font-size: 35px;
        margin-bottom: 8px;
      }

      p {
        font-size: 16px;
      }

      button {
        margin: 0;
        margin-left: 16px;
        margin-right: 16px;

        @include media-breakpoint-up(xl) {
          width: 200px;
        }
      }
    }

    &.type-image {
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      transition: background-size 0.2s;
      padding: 28px 20px;
      border-radius: 16px;
      width: 300px;
      height: 320px;

      &:hover,
      &:focus {
        &:not(.disabled) {
          background-size: 105%;
        }
      }

      p {
        color: $white;
        text-align: center;
        font-weight: $font-weight-semi-bold;
      }

      button {
        margin: 0;
        margin-left: 28px;
        margin-right: 28px;

        &.disabled,
        &:disabled {
          border-color: inherit;
          background-color: inherit;
        }
      }
    }
  }

  &.mobile {
    width: 100%;
    min-height: 100px;
    margin-bottom: 24px;

    &.type-plain {
      .container {
        justify-content: space-between;
        padding-left: 24px;
        padding-right: 0;

        .title {
          font-size: 30px;
        }

        .description {
          padding-left: 18px;
          padding-right: 16px;
          font-size: 14px;
          // max-width: 280px;
        }

        button {
          margin: 0;
          height: 100%;
          border-radius: 0;

          @include media-breakpoint-down(md) {
            max-width: 110px;
            padding-right: 14px;
            padding-left: 14px;
          }
        }
      }
    }

    &.type-image {
      .image-container img {
        width: 101px;
        height: 100%;
        // A little enhance for mobile devices and
        // only for browsers that support object-fit property.
        object-fit: cover;
      }

      &:hover,
      &:focus {
        .container .chevron {
          right: 20px;
        }
      }

      .container {
        padding: 16px;
        padding-right: 60px;
        background-color: $pink-800;
        position: relative;
        margin-left: -1px; // Need this to avoid small gap between image when changing screen size.

        .chevron,
        .spinner {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 24px;
        }

        .chevron {
          height: 14px;
          width: 14px;
          transition: right 0.2s;
        }

        .spinner,
        .spinner span {
          height: 14px;
          width: 14px;
        }

        .title {
          color: $white;
          font-weight: $font-weight-bold;
          text-transform: uppercase;
        }

        .description {
          color: $white;
          font-size: 14px;
        }
      }
    }
  }
}
