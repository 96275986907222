/////////////////
/// BOOTSTRAP ///
/////////////////

@import "~bootstrap/scss/functions";

// Local variables and bootstrap variable overrides.
@import "variables";

// Other parts of bootstrap.
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark.scss";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";

/////////////
/// LOCAL ///
/////////////

@import 'basics';
@import 'typography';
@import 'grid';
@import 'icons';
@import 'body-blocks';
@import 'behaviors';
@import 'utils';
@import 'a11y';

//////////////
/// GENERAL///
//////////////

body.with-popup {
  height: 100%;
  overflow: hidden;
}

nav,
main {
  // BUG: https://bugzilla.mozilla.org/show_bug.cgi?id=1125767
  // Affects absolutely positioned elements like BBAppealOverlay. With transition,
  // would appear to jump from one position context to the other.
  // transition: filter .2s ease-in-out;

  &.with-popup {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }
}

// This is needed to properly handle popup from sociablekit.com which used in TiktokSociablekit component.
// Otherwise popup will be overlapped by navbar and close button may be not accessible.
#__next {
  position: relative;
  z-index: 1;
}

div, nav {
  &.left {
    float: left;
  }

  &.right {
    float: right;
  }
}

@include placeholder {
  color: $slate-400 !important;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}

.main-noscript {
  position: fixed;
  z-index: 10000;
  width: 85%;
  text-align: center;
  font-weight: $font-weight-bold;
  font-size: 18px;
}

// Replicating Bootstrap v4 media utility.
// https://github.com/twbs/bootstrap/pull/28265.
.media {
  display: flex;
  align-items: flex-start;

  &-body {
    flex:1 1;
  }
}

// Replicating label reboot from Bootstrap v4.
// This prop has disappeared from Bootstrap v5.
label {
  margin-bottom: 0.5rem;
}

// Next.js doesn't allow importing non-scoped styles on component level so they
// are all listed below as one global style sheet.
// When the component is migrated to SCSS modules, it can be deleted from here.
// @import '../components/01_atoms/Breadcrumbs/style';
// @import '../components/01_atoms/Button/style';
// @import '../components/01_atoms/ChartDonut/style';
// @import '../components/01_atoms/Counter/style';
@import '../components/01_atoms/FormElements/CheckboxesField/style';
// @import '../components/01_atoms/FormElements/FieldError/style';
// @import '../components/01_atoms/FormElements/FieldTemplate/style';
// @import '../components/01_atoms/FormElements/RadiosField/style';
// @import '../components/01_atoms/FormElements/RadiosButton/style';
@import '../components/01_atoms/FormElements/SelectField/style';
@import '../components/01_atoms/FormElements/SelectField/SelectFieldStandalone/style';
@import '../components/01_atoms/FormElements/TextField/style';
@import '../components/01_atoms/FormElements/TextFieldPill/style';
@import '../components/01_atoms/FormElements/ToggleGroupField/style';
// @import '../components/01_atoms/FrayedEdge/style';
@import '../components/01_atoms/GoogleTranslateWidget/style';
// @import '../components/01_atoms/IconButton/style';
// @import '../components/01_atoms/IconToggle/style';
// @import '../components/01_atoms/PageTransitionBar/style';
// @import '../components/01_atoms/ProgressBar/style';
// @import '../components/01_atoms/ReCaptchaGoogle/style';
// @import '../components/01_atoms/Slider/style';
// @import '../components/01_atoms/Spinner/style';
// @import '../components/01_atoms/StepIndicator/style';
// @import '../components/01_atoms/Tag/style';
// @import '../components/01_atoms/Tag/TagIcon/style';
// @import '../components/01_atoms/Tag/TagText/style';
// @import '../components/01_atoms/Tagline/style';
// @import '../components/01_atoms/Toggle/CardToggle/style';
// @import '../components/01_atoms/Toggle/IconToggle/style';
@import '../components/02_molecules/ButtonField/style';
@import '../components/02_molecules/CookieConsentDialog/style';
// @import '../components/02_molecules/DropdownFiltersForm/style';
// @import '../components/02_molecules/FeaturedStory/style';
@import '../components/02_molecules/Footer/FooterBigMenu/style';
@import '../components/02_molecules/Footer/FooterCopy/style';
@import '../components/02_molecules/Footer/FooterLogos/style';
@import '../components/02_molecules/Footer/FooterSmallMenu/style';
// @import '../components/02_molecules/Footer/FooterSocialLinks/style';
@import '../components/02_molecules/FrayedContainer/style';
@import '../components/02_molecules/Header/HeaderDropdown/style';
// @import '../components/02_molecules/Header/HeaderMenu/style';
// @import '../components/02_molecules/IconStat/style';
// @import '../components/02_molecules/Item/ItemWithImage/style';
// @import '../components/02_molecules/Item/ItemWithImageHoverable/style';
// @import '../components/02_molecules/Item/ItemWithImageHoverableSmall/style';
// @import '../components/02_molecules/Item/ItemWithLogo/style';
// @import '../components/02_molecules/Link/LinkChevron/style';
// @import '../components/02_molecules/Link/LinkTitle/style';
// @import '../components/02_molecules/Paginator/style';
// @import '../components/02_molecules/Paginator/PaginatorMini/style';
// @import '../components/02_molecules/Popup/style';
@import '../components/02_molecules/Search/SearchInput/style';
@import '../components/02_molecules/Search/SearchResult/style';
// @import '../components/02_molecules/Switcher/style';
// @import '../components/02_molecules/TextFieldButton/style';
@import '../components/02_molecules/TextInputWithSelectsForm/style';
// @import '../components/02_molecules/TileItem/style';
@import '../components/02_molecules/ToggleGroup/style';
@import '../components/02_molecules/TopBanner/AppealBanner/style';
@import '../components/02_molecules/TopBanner/EventBanner/style';
// @import '../components/03_organisms/BodyBlocks/BBAccordion/style';
// @import '../components/03_organisms/BodyBlocks/BBAppealBanner/style';
@import '../components/03_organisms/BodyBlocks/BBAppealOverlay/style';
@import '../components/03_organisms/BodyBlocks/BBAppealOverlay/BBAppealOverlayDesktop/style';
@import '../components/03_organisms/BodyBlocks/BBAppealOverlay/BBAppealOverlayMobile/style';
@import '../components/03_organisms/BodyBlocks/BBAppealOverlay/BBAppealOverlayPopout/style';
// @import '../components/03_organisms/BodyBlocks/BBBreadcrumbs/style';
// @import '../components/03_organisms/BodyBlocks/BBCarousel/style';
// @import '../components/03_organisms/BodyBlocks/BBCarousel/BBCarouselDesktop/style';
// @import '../components/03_organisms/BodyBlocks/BBCarousel/BBCarouselDesktop/BBCarouselDesktopStandard/style';
// @import '../components/03_organisms/BodyBlocks/BBCarousel/BBCarouselDesktop/BBCarouselDesktopStory/style';
// @import '../components/03_organisms/BodyBlocks/BBCarousel/BBCarouselMobile/style';
// @import '../components/03_organisms/BodyBlocks/BBContentOverview/style';
// @import '../components/03_organisms/BodyBlocks/BBCountryList/style';
// @import '../components/03_organisms/BodyBlocks/BBCountryListSwitcher/style';
//@import '../components/03_organisms/BodyBlocks/BBCountryMap/style';
// @import '../components/03_organisms/BodyBlocks/BBDetailMeta/style';
@import '../components/03_organisms/BodyBlocks/BBDonationsHeader/BBDonationsHeaderDesktop/style';
@import '../components/03_organisms/BodyBlocks/BBDonationsHeader/BBDonationsHeaderMobile/style';
//@import '../components/03_organisms/BodyBlocks/BBDonationWidget/style';
// @import '../components/03_organisms/BodyBlocks/BBFeaturedCountryNews/style';
// @import '../components/03_organisms/BodyBlocks/BBFeaturedNews/style';
// @import '../components/03_organisms/BodyBlocks/BBFeaturedStories/style';
// @import '../components/03_organisms/BodyBlocks/BBFocusArea/style';
// @import '../components/03_organisms/BodyBlocks/BBForms/BBSimpleTextForm/style';
// @import '../components/03_organisms/BodyBlocks/BBHero/style';
// @import '../components/03_organisms/BodyBlocks/BBHero/BBHeroImage/style';
// @import '../components/03_organisms/BodyBlocks/BBHero/BBHeroLanding/style';
// @import '../components/03_organisms/BodyBlocks/BBHero/BBHeroEmergency/style';
// @import '../components/03_organisms/BodyBlocks/BBHero/BBHeroVideo/style';
// @import '../components/03_organisms/BodyBlocks/BBHero/BBHeroTagline/style';
// @import '../components/03_organisms/BodyBlocks/BBHeroWithMoneyHandles/style';
// @import '../components/03_organisms/BodyBlocks/BBImageGrid/style';
//@import '../components/03_organisms/BodyBlocks/BBImpactStats/style';
//@import '../components/03_organisms/BodyBlocks/BBImpactTimeline/style';
// @import '../components/03_organisms/BodyBlocks/BBMoneyHandles/style';
// @import '../components/03_organisms/BodyBlocks/BBMoneyHandlesNew/style';
//@import '../components/03_organisms/BodyBlocks/BBOurFocus/style';
// @import '../components/03_organisms/BodyBlocks/BBPartners/style';
// @import '../components/03_organisms/BodyBlocks/BBProgramme/style';
// @import '../components/03_organisms/BodyBlocks/BBPublicationAuthor/style';
// @import '../components/03_organisms/BodyBlocks/BBPublications/style';
// @import '../components/03_organisms/BodyBlocks/BBQuickDonateWidget/style';
// @import '../components/03_organisms/BodyBlocks/BBQuote/style';
// @import '../components/03_organisms/BodyBlocks/BBQuote/BBQuoteImage/style';
// @import '../components/03_organisms/BodyBlocks/BBQuote/BBQuoteSimple/style';
//@import '../components/03_organisms/BodyBlocks/BBRelatedContent/style';
// @import '../components/03_organisms/BodyBlocks/BBResourcesDownload/style';
// @import '../components/03_organisms/BodyBlocks/BBResourcesList/style';
//@import '../components/03_organisms/BodyBlocks/BBSearch/style';
// @import '../components/03_organisms/BodyBlocks/BBSidebarHighlights/style';
//@import '../components/03_organisms/BodyBlocks/BBSocialMediaEmbed/style';
//@import '../components/03_organisms/BodyBlocks/BBSocialMediaSideShare/style';
//@import '../components/03_organisms/BodyBlocks/BBSocialMediaSideShare/BBSocialMediaSideShareDesktop/style';
//@import '../components/03_organisms/BodyBlocks/BBSocialMediaSideShare/BBSocialMediaSideShareMobile/style';
//@import '../components/03_organisms/BodyBlocks/BBSocialMediaSideShare/BBSocialMediaSideSharePopup/style';
// @import '../components/03_organisms/BodyBlocks/BBSoundCloud/style';
// @import '../components/03_organisms/BodyBlocks/BBStepper/style';
//@import '../components/03_organisms/BodyBlocks/BBTeaserWithPager/style';
// @import '../components/03_organisms/BodyBlocks/BBThreeTile/style';
// @import '../components/03_organisms/BodyBlocks/BBTimeSpent/style';
//@import '../components/03_organisms/BodyBlocks/BBVideo/style';
// @import '../components/03_organisms/BodyBlocks/BBSectionWithCharts/style';
//@import '../components/03_organisms/BodyBlocks/CallToActions/style';
// @import '../components/03_organisms/BodyBlocks/CallToActions/BBCTADual/style';
// @import '../components/03_organisms/BodyBlocks/CallToActions/BBCTAPrimary/style';
// @import '../components/03_organisms/BodyBlocks/CallToActions/BBCTASecondary/style';
// @import '../components/03_organisms/BodyBlocks/CallToActions/BBCTAEmergencyPrimary/style';
// @import '../components/03_organisms/BodyBlocks/CallToActions/BBInlineCTA/style';
//@import '../components/03_organisms/BodyBlocks/Text/BBText/style';
// @import '../components/03_organisms/BodyBlocks/Text/BBCollapsedText/style';
// @import '../components/03_organisms/BodyBlocks/Text/BBHeading/style';
// @import '../components/03_organisms/BodyBlocks/Text/BBHeroHeading/style';
// @import '../components/03_organisms/BodyBlocks/BBFeaturedEvent/style';
// @import '../components/03_organisms/Header/style';
@import '../components/03_organisms/Footer/style';
// @import '../components/04_templates/ContentLayouts/CenteredColumn/style';
// @import '../components/04_templates/ContentLayouts/TwoColumns/style';
// @import '../components/04_templates/PageLayouts/HomePage/style';
// @import '../components/04_templates/PageLayouts/LandingPage/style';

@import './styles-cnet.scss';
