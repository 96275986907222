@import '../../../../../styles/basics';

.bb.bb-donations-header.bb-donations-header-mobile {
  margin-bottom: 20px;

  .bb-hero-image {
    margin-bottom: 40px;
  }

  > .container > * {
    margin-bottom: 30px;
  }

  > .container > p {
    margin-top: -16px;
    margin-bottom: 40px;
    font-size: 18px;
  }

  form {
    margin-bottom: 10px !important;

    > * {
      margin-bottom: 30px;
    }

    button {
      margin-bottom: 10px;
    }

    [data-component="field-template"] {
      margin-left: 24px;
      margin-bottom: 0;
      flex-grow: 1;
    }

    // positions the validation label so that the rest of the element's flow isn't disrupted on desktop
    [data-component="field-error"] {
      position: absolute;
      left: 0;
      top: 45px;
    }
  }

  .bottom-description {
    text-align: center;
    color: $pink-800;
    margin: 14px 35px 10px;
  }
}
