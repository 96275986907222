@import '../../../styles/basics';

#google_translate_element {
  display: inline-block;
  padding-right: 8px;

  .goog-te-gadget {
    color: transparent;
    font-size: 0;
    &:nth-child(2) {
      display: none;
    }
  }

  .goog-logo-link {
    display: none;
  }

  .goog-te-combo {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 180px;
    border: 1px solid $slate-200;
    background-color: $white;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px 10px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' overflow='visible' preserveAspectRatio='none' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath xmlns:default='http://www.w3.org/2000/svg' d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z' style='fill:rgb(18, 18, 18)' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E");

    &:focus {
      border-color: $turquoise-1200;
      color: $slate-1200;
      outline: none;
    }
  }
}

.google-powered-by {
  display: inline-block;
  a,
  a:hover {
    color: $slate-1000;
    font-size: 12px;
    text-decoration: none;
  }
}

// On mobile Google Translate top bar doesn't fit to screen and close button
// becomes invisible. As a workaround, we make sure right part of the bar is
// always visible by setting right: 0.
.goog-te-banner-frame.skiptranslate {
  @media (max-width: 490px) {
    left: auto;
    right: 0;
    width: 490px;
  }
}
