@import '../../../../styles/basics';

.footer-logos {
  @include media-breakpoint-up(md) {
    margin-bottom: 26px;
  }

  ul {
    @include list-unstyled();

    li {
      margin-bottom: 2em;
      padding-left: 0;

      img {
        height: 46px;
      }
      a {
        transition: opacity 0.2s;
        &:hover {
          transition: opacity 0.2s;
          opacity: 0.8;
        }
        &:active {
          transition: opacity 0.2s;
          opacity: 0.7;
        }
      }

      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-right: 1.5em;
        img {
          height: 58px;
        }
        &:last-child {
          margin-right: 0;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-right: 3.5em;
        img {
          height: 60px;
        }
      }
    }
  }
}
