*:focus-visible {
  outline-style: solid;
  outline-width: 2px;
}

*:focus-visible:not(a) {
  outline: 2px solid $white;
  box-shadow: 0 0 0 4px $deep-blue-500;
  // Avoid elements overlapping that can hide focus state.
  z-index: 1;
}

a.button:focus-visible {
  outline: 2px solid $white;
  box-shadow: 0 0 0 4px $deep-blue-500;
}
