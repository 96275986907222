@import '../../../../styles/basics';

.footer-big-menu {
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .footer-big-submenu {
    margin-bottom: 20px;

    &__label {
      margin-bottom: 12px;
    }
  }

  @include media-breakpoint-up(md) {
    column-gap: 56px;
    flex-direction: row;
    .footer-big-submenu {
      width: calc(50% - 28px);
      &__label {
        margin-bottom: 20px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    justify-content: flex-start;
  }
  @media (min-width: 1400px) {
    .footer-big-submenu {
      width: auto;
    }
  }
}
