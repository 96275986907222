@import '../../../../styles/basics';

.footer-copy {
  font-size: 14px;
  line-height: $line-height-large;
  color: $gray-1000;

  p {
    margin: 0;
    font-size: 14px;
    line-height: $line-height-large;
    color: $gray-1000;
  }
}
