/// Configure Boostrap v5 variables ///

// Links.
$link-color: $turquoise-1000;
$link-decoration: none;
$link-hover-color: $turquoise-1200;
$link-hover-decoration: underline;

// Global scrolling.
$enable-smooth-scroll: false;
