@import '../../../../../styles/basics';

.bb-appeal-overlay-popout {
  position: relative;
  max-width: 280px;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.5);
  border: 2px solid $white;
  background: $white;
  @include media-breakpoint-up(sm) {
    max-width: none;
    width: 325px;
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.95), $white);
    transition: left 0.2s;
    // can't use % because we want animation when sliding back in
    left: -337px;
  }

  &.sticky {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 6750;
  }

  > img {
    max-width: 276px;
    @include media-breakpoint-up(sm) {
      max-width: none;
    }
  }

  &.open {
    left: 0;
  }

  .close-overlay {
    position: absolute;
    cursor: pointer;
    border: 2px solid $white;
    border-radius: 100%;
  }

  .gradient {
    position: absolute;
    top: 134px;
    width: 100%;
    height: 30px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), $white);
    @include media-breakpoint-up(sm) {
      top: 160px;
    }
    @include media-breakpoint-up(md) {
      top: 150px;
      height: 40px;
    }
  }

  .content {
    position: relative;
    padding: 0 16px 8px 16px;
    top: -2px;
    @include media-breakpoint-up(md) {
      top: -10px;
    }

    > .header-two {
      margin-bottom: 8px;
    }

    > p {
      margin-bottom: 12px;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
      }
    }

    > .d-flex {
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        margin-bottom: 32px;
      }

      > div {
        line-height: 18px;

        &:nth-child(2) {
          margin-left: 12px;
          @include media-breakpoint-up(md) {
            margin-left: 32px;
          }
        }

        mark {
          color: $slate-1200;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.popup.popup-appeal-overlay {
  > .container {
    padding-top: 0;
    padding-bottom: 0;
  }

  > .popup-navigation {
    display: none;
  }
}
