@import '../../../../../styles/basics';

.donation-form-payment {
  .form-field-with-description.bic-field,
  .form-field-with-description.iban-field {
    margin-bottom: 36px;
  }
  .radio-buttons-direct-debit-sepa {
    > .field-type-radios {
      margin-bottom: 36px;

      > label {
        font-weight: $font-weight-bold;
        font-size: 16px;
        margin-bottom: 2px;

        .validation {
          font-weight: $font-weight-regular;
          font-size: 14px;
          color: $slate-1000;
        }

      }
    }

    .text-with-popup {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  .hr {
    border-top: 1px solid $slate-200;
    width: 100%;
  }
}
