/////////////
/// ICONS ///
/////////////

img {
  object-fit: contain;
  transition: transform 0.2s;
}

.flipped {
  transform: rotate(180deg);
}

// Links that contain svg icons.
a {
  > svg path {
    transition: fill 0.2s;
  }
  &:hover > svg path {
    transition: fill 0.2s;
    fill: $turquoise-1200;
  }
  &:active > svg path {
    transition: fill 0.2s;
    fill: $turquoise-1200;
  }
}
