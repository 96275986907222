@import '../../../styles/basics';

.frayed-container {
  @include bb-with-background-padding();
  background-color: $turquoise-1000;

  *:not(input) {
    color: $white !important;
  }

  h2 {
    @include bb-heading-spacing();
  }
}
