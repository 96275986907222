@import '../../../../styles/basics';

.header-dropdown {
  width: 100%;
  position: absolute;
  background-color: $white;
  z-index: 6000;
  margin-top: -5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;

  &.desktop {
    padding: 0;

    // The following code needs to be able to focus top menu item during tabbing by keyboard.
    // https://css-tricks.com/a-css-approach-to-trap-focus-inside-of-an-element/#aa-the-css-trick-to-focus-trapping
    &.opened-desktop-menu:not(:focus-within) {
      background-color: rgb(255, 255, 254);
      transition: background-color 0.01ms;
    }

    .cta {
      background-color: $biscuit-600;
      padding-top: 40px;
      padding-bottom: 40px;
      // the CTA should scale with the width of the display - minimum 400px,
      // and always 40px of right padding from the CTA edge, taking into account
      // its known width with image
      padding-left: calc(34% - 264px - 40px);
      width: 34%;
      min-width: 400px;

      img,
      p {
        width: 264px;
      }

      img {
        height: 200px;
      }

      p {
        margin-top: 10px;
        margin-bottom: 0;
      }

      [data-component^="button"] {
        margin-top: 20px;
      }
    }

    .row.ie11-compat {
      flex-flow: row nowrap;

      > .col {
        flex-basis: auto;
      }
    }

    .icon-list.bold:before {
      width: 9px;
      height: 10px;
      background-size: cover;
      top: 9px;
    }

    .links {
      margin-top: 48px;
      padding-left: 60px;
      // compensating for absence of side padding due to cta element
      margin-right: 20px;

      ul {
        margin-bottom: 40px;
      }

      & > *:not(:first-child) {
        padding-left: 50px;
      }
    }
  }

  &.mobile {
    padding: 10px 20px;

    .collapser {
      line-height: 60px;

      &:not(:last-child) {
        border-bottom: 1px solid $slate-200;
      }
    }

    .nav-menu-accordion {
      display: table;
      width: 100%;

      li.icon-list {
        margin-bottom: 16px;

        &:first-child {
          margin-top: 22px;
        }

        &:last-child {
          margin-bottom: 22px;
        }
      }

      .collapser {
        &.opened {
          border-bottom: 1px solid $slate-200;
        }

        > div:nth-child(2) {
          position: absolute;
          height: 60px;
          width: 60px;
          right: 0;
          margin-right: 20px;
          border-left: 1px solid $slate-200;

          .icon-toggle img {
            color: transparent;
            height: 12px;
            margin-left: 22px;
          }
        }
      }

      .contents {
        border-bottom: 1px solid $slate-200;

        > .nav-menu-accordion > :first-child {
          padding-left: 10px;
        }

        > li.icon-list {
          margin-left: 10px;
        }
      }
    }
  }
}
