@import '../../../../../styles/basics';

// Display address line fields close to each other.
.field-address\.addressLine1 {
  margin-bottom: 10px;
}
.field-address\.addressLine2 {
  label {
    display: none;
  }
}
// Display county & postal code on columns on desktop.
@include media-breakpoint-up(md) {
  .field-address-administrative-area-wrapper {
    float: left;
    width: 50%;
    padding-right: 8px;
  }
  .field-address_postalCode-wrapper {
    display: inline-block;
    width: 50%;
    padding-left: 8px;
  }

  .donation-form-source-codes-area {
    &:before {
      content: '';
      display: table;
      clear: both;
    }
  }
}

